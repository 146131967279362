@media screen and (max-width: 959px) {
    .disappear960 {
        display: none!important;
    }
    .response960 {
        padding-bottom: 0!important;
    }
    .appear960 {
        display: block!important;
    }
}

.dropdown:hover .dropdown-content {
    display: block;
}

@keyframes pulseAnimation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    }
    40% {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0px;
    }
}

@keyframes shiftRightAnimation {
    0% {
        transform: translateX(12%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(12%);
    }
}